<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-faltantes-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Listado Faltantes
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
  <div class="tab-pane fade active show" id="Filtros">
    <div class="card-body">
      <label for="">Rango de fechas descargue</label>
      <div class="row">
        <div class="col-md-6">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="form.fecha_inicial"
            :class="
              $v.form.fecha_inicial.$invalid
                ? 'is-invalid'
                : 'is-valid'
            "
            @input="validaFechas"
          />
        </div>
        <div class="col-md-6">
          <input
            type="date"
            class="form-control form-control-sm"
            v-model="form.fecha_final"
            :class="
              $v.form.fecha_final.$invalid
                ? 'is-invalid'
                : 'is-valid'
            "
            @input="validaFechas"
          />
        </div>
      </div>
      
      <!-- Sitio Cargue -->
      <div class="row mt-2 pt-2">
        <label for="sitio_id" class="col-md-3 text-start">Sitio Cargue</label>
        <div class="col-md-9 d-flex justify-content-end">
          <v-select
            :class="[$store.getters.getDarkMode ? 'dark-vselect' : '']"
            v-model="sitio"
            placeholder="Campos"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.sitios"
            :filterable="true"
            @input="getSelectSitios()"
          ></v-select>
        </div>
      </div>

      <!-- Sitio Descargue -->
      <div class="row mt-2 pt-2">
        <label for="sitio_id" class="col-md-3 text-start">Sitio Descargue</label>
        <div class="col-md-9 d-flex justify-content-end">
          <v-select
            :class="[$store.getters.getDarkMode ? 'dark-vselect' : '']"
            v-model="sitioDes"
            placeholder="Campos"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.sitios"
            :filterable="true"
            @input="getSelectSitiosDes()"
          ></v-select>
        </div>
      </div>

      <!-- Rutas -->
      <div class="row mt-2 pt-2">
        <label for="sitio_id" class="col-md-3 text-start">Rutas</label>
        <div class="col-md-9 d-flex justify-content-end">
          <v-select
            :class="[$store.getters.getDarkMode ? 'dark-vselect' : '']"
            v-model="ruta"
            placeholder="Campos"
            label="nombre"
            class="form-control form-control-sm p-0"
            :options="listasForms.rutas"
            :filterable="true"
            @input="getSelectRutas()"
          ></v-select>
        </div>
      </div>
    </div>
  </div>
</div>

          </div>
          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExce()"
                v-if="
                  $store.getters.can('hidrocarburos.reportes.solicitudExport')
                "
                v-show="!$v.form.$invalid"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";
import { required } from "vuelidate/lib/validators";
export default {
  name: "ReporteFaltantes", //llegada tambien
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      sitio: {},
      sitioDes: {},
      nominacion: {},
      ruta: {},
      form: {
        fecha_inicial: "",
        hora_inicial: "00:00:00",
        fecha_final: "",
        hora_final: "23:59:00",
      },
      //SE CREAN FILTROS PARA EL BACK
      filtros: {
        id: null,
        sitio_id: null,
        sitio_des_id: null,
        ruta_id: null,
        nominacion_id: null,
        fecha: null,
      },
      //OBTIENE LAS LISTAS DE LOS CORRESPONDIENTES A LAS EMPRESAS
      listasForms: {
        sitios: [],
        nominaciones: [],
      },
    };
  },
  validations: {
    form: {
      fecha_inicial: {
        required,
      },
      fecha_final: {
        required,
      },
    },
  },
  methods: {
    validaFechas() {
      if (this.form.fecha_inicial) {
        const hoy = new Date();
        const fecha_inicial = new Date(this.form.fecha_inicial);
        fecha_inicial.setDate(fecha_inicial.getDate());

        if (fecha_inicial >= hoy) {
          this.form.fecha_inicial = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        var fecha_menor = new Date(this.form.fecha_inicial);
        var fecha_mayor = new Date(this.form.fecha_final);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_inicial && this.form.fecha_final) {
        fecha_menor = new Date(this.form.fecha_inicial).getTime();
        fecha_mayor = new Date(this.form.fecha_final).getTime();
        var diff = fecha_mayor - fecha_menor;
        var fecha_limite = diff / (1000 * 60 * 60 * 24);
        if (fecha_limite > 30) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `Las fechas no pueden ser mayor a 30 dias...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
      if (this.form.fecha_final) {
        var hoy = new Date();
        const fecha_final = new Date(this.form.fecha_final);
        fecha_final.setDate(fecha_final.getDate());
        if (fecha_final >= hoy) {
          this.form.fecha_final = null;
          this.$swal({
            icon: "error",
            title: `La fecha final debe ser menor o igual a la fecha actual...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },
    async getNominaciones() {
      this.cargando = true;
      axios.get("/api/hidrocarburos/nominacion/list").then((response) => {
        this.listasForms.nominaciones = response.data;
        this.cargando = false;
      });
    },
    async getSitios() {
      this.cargando = true;
      axios.get("/api/admin/sitios/lista").then((response) => {
        this.listasForms.sitios = response.data;
        this.cargando = false;
      });
    },
    async getRutas() {
      this.cargando = true;
      axios.get("/api/admin/rutas/lista").then((response) => {
        this.listasForms.rutas = response.data;
        this.cargando = false;
      });
    },
    getSelectRutas(){
      this.filtros.ruta = {};
      this.filtros.ruta_id = null;
      if (this.ruta) {
        this.filtros.ruta = this.ruta;
        this.filtros.ruta_id = this.ruta.id;
      }
    },

    getSelectSitios() {
      this.filtros.sitio = {};
      this.filtros.sitio_id = null;
      if (this.sitio) {
        this.filtros.sitio = this.sitio;
        this.filtros.sitio_id = this.sitio.id;
      }
    },
    getSelectSitiosDes() {
      this.filtros.sitioDes = {};
      this.filtros.sitio_des_id = null;
      if (this.sitioDes) {
        this.filtros.sitioDes = this.sitioDes;
        this.filtros.sitio_des_id = this.sitioDes.id;
      }
    },
  

    //MANDA A LLAMAR LA FUNCION EXPORT EN EL BACK
    generarListadoExce() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/faltantes/export",
        data: { form: this.form, filtros: this.filtros },
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getNominaciones();
    await this.getSitios();
    await this.getRutas();
    this.cargando = false;
  },
};
</script>
